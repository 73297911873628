import { CaretDownOutlined, SettingOutlined } from '@ant-design/icons';
import { Pagination, Spin, Tooltip } from 'antd';
import { debounce } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { usePrevious } from '../../../../hooks/usePrevious';
import ModalSetting from './ModalSetting';
import { TableStyled } from './styled';
import { uniqBy } from 'lodash';

const TableCustom = ({ columns, nameTable, columnsDefault, filter, setFilter, callApi, onHeaderRow, rowSelection, response, className, dataResponse, setAllDatas, type, columnTotal, getRowClassName, pagination = true }, ref) => {
    const refModalSetting = useRef(null);
    const [columnSetting, setColumnSetting] = useState([]);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const prevFilter = usePrevious(filter);

    const debounceLoadData = useCallback(
        debounce((filter, page, size) => getData(filter, page, size), 300),
        [],
    );
    useImperativeHandle(ref, () => ({
        reload: (type) => {
            if (data?.length === 1 && type == 'delete' && page !== 0) {
                setPage(page - 1);
                getData(filter, page - 1, size);
            } else {
                getData(filter, page, size);
            }
        },
        getData: () => {
            return data;
        },
    }));

    useEffect(() => {
        if (!loading) {
            let columnLocal = localStorage.getItem(nameTable);
            if (columnLocal) {
                setColumnSetting(JSON.parse(columnLocal));
            } else {
                setColumnSetting([...columns]);
            }
        }
    }, [loading]);

    useEffect(() => {
        if (prevFilter !== filter) {
            debounceLoadData(filter, 0, size);
            setPage(0);
        } else {
            getData(filter, page, size);
        }
    }, [page, size, filter]);

    const getData = async (filter, page, size) => {
        setLoading(true);
        let params = {
            page,
            size,
            ...filter,
        };
        try {
            let res = await callApi(params);
            if (response) {
                if (res && res[response] && res?.totalElements > 0) {
                    setData(res[response]);
                    setTotal(res?.totalElements);
                } else {
                    setData([]);
                    setTotal(0);
                }
                setLoading(false);
                return;
            }
            if (res?.data) {
                if (res && res?.data?.totalElements > 0) {
                    setData(res?.data?.content);
                    setTotal(res?.data?.totalElements);
                } else {
                    setData([]);
                    setTotal(0);
                }
            } else {
                if (res && res?.totalElements > 0) {
                    setData(res?.content);
                    dataResponse && dataResponse(res?.content);
                    setTotal(res?.totalElements);
                }
                else {
                    setData([]);
                    setTotal(0);
                }
            }
            if (type === 'report') {
                if (res[0]?.series?.data) {
                    setData(res[0]?.series?.data);
                    setAllDatas && setAllDatas(res[0]?.series?.data);
                } else {
                    setData([]);
                    setAllDatas([]);
                }
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            toast.error('Có lỗi xảy ra. Xin vui lòng thử lại !');
        }
    };

    const onSaveSetting = (dataTable) => {
        // save setting to local storage
        let newDataTable = [...dataTable];
        setColumnSetting((prev) => {
            let newColumn;
            newColumn = [prev[0], ...newDataTable, prev.slice(-1).pop()];
            newColumn = uniqBy(newColumn, (item) => {
                return item.key;
            });
            localStorage.setItem(nameTable, JSON.stringify(newColumn));
            return newColumn;
        });
    };

    const onOpenModalSetting = () => {
        refModalSetting.current.onOpen();
    };

    const renderColumn = (column, item, index, render) => {
        if (render) {
            return render(item, column, index);
        }
    };

    const getColumns = () => {
        let columnsNew = columnSetting
            .filter((item) => item?.isShow)
            .map((column, index) => {
                const render = columnsDefault?.find((item) => item?.key === column?.key)?.render;
                if (column.key == 'actions')
                    return {
                        ...column,
                        key: `${column.key}-${index}`,
                        title: () => {
                            return (
                                <div className="table-header__actions">
                                    <span>Thao tác</span>
                                </div>
                            );
                        },
                        render: (_, item, index) => renderColumn(column, item, index, render),
                    };

                return {
                    ...column,
                    // fixed: index == 1 ? 'left' : column.fixed ? column.fixed : '',
                    render: (_, item, index) => renderColumn(column, item, index, render),
                };
            });
        columnsNew.push({
            width: 60,
            align: 'right',
            title: () => {
                return (
                    <Tooltip title="Cài đặt hiển thị" placement="leftTop">
                        <div onClick={() => onOpenModalSetting()}>
                            <SettingOutlined style={{ fontSize: '20px' }} />
                            <span>
                                <CaretDownOutlined />
                            </span>
                        </div>
                    </Tooltip>
                );
            },
        });
        return columnsNew;
    };

    const paginationChange = (pageChange, sizeChange) => {
        if (pageChange !== page + 1) {
            setPage(pageChange - 1);
            setSize(sizeChange);
        }
    };

    const onShowSizeChange = (pageChange, sizeChange) => {
        setPage(0);
        setSize(sizeChange);
    };

    const dataSource = useMemo(() => {
        return data?.map((item, i) => ({
            key: page * size + i + 1,
            stt: page * size + i + 1,
            ...item,
        }));
    }, [data]);

    const formatData = (dataSource, type, columnTotal) => {
        if (dataSource?.length && type === 'report') {
            const groupedData = data.reduce((acc, item) => {
                if (item.variable === "Tổng") return acc; // Bỏ qua phần tử có "variable" là "Tổng"

                const variableKey = item.variable !== null ? item.variable : 'null';
                if (!acc[variableKey]) acc[variableKey] = { variable: item.variable, stt: 0 };

                acc[variableKey][item.key.toLowerCase()] = (acc[variableKey][item.key.toLowerCase()] || 0) + item.value;
                acc[variableKey].stt = item.stt; // Update stt to the latest

                return acc;
            }, {});

            // Tính tổng giá trị cho từng trường
            const totals = Object.values(groupedData).reduce((acc, item) => {
                Object.keys(item).forEach(key => {
                    if (key !== 'variable' && key !== 'stt') {
                        acc[key] = (acc[key] || 0) + item[key];
                    }
                });
                return acc;
            }, {});

            // Thêm đối tượng có "variable: 'Tổng'" vào đầu mảng kết quả với tổng của các trường
            const result = Object.values(groupedData).map((item, index) => ({
                ...item,
                stt: index + 1
            }));

            result.unshift({
                variable: 'Tổng',
                ...totals
            });
            return result;
        } else {
            return [...dataSource]
        }
    }

    const columnSettingFormat = useMemo(() => {
        return columnSetting?.filter((item) => item?.key !== 'actions' && item?.key?.toLowerCase() !== 'stt');
    }, [columnSetting]);

    return (
        <>
            <ModalSetting
                ref={refModalSetting}
                data={columnSettingFormat}
                onSaveSetting={onSaveSetting}
                columnsDefault={columnsDefault}
            />
            <div className="wrap-table-data">
                <Spin size="large" tip="LOADING..." spinning={loading}>
                    <div className="wrap-table">
                        <TableStyled
                            key={page}
                            columns={getColumns()}
                            dataSource={formatData(dataSource, type, columnTotal)}
                            pagination={false}
                            scroll={{ x: 600, y: 300 }}
                            onHeaderRow={onHeaderRow}
                            rowSelection={rowSelection}
                            className={className}
                            rowClassName={getRowClassName}
                        />
                        {pagination &&
                            <Pagination
                                total={total}
                                showSizeChanger
                                showTotal={(total, range) => {
                                    return `${range[0]}-${range[1]} trên tổng ${total}`;
                                }}
                                defaultPageSize={size}
                                onChange={paginationChange}
                                pageSizeOptions={[20, 50, 100]}
                                current={page + 1}
                                onShowSizeChange={onShowSizeChange}
                            />
                        }
                    </div>
                </Spin>
            </div>
        </>
    );
};
export default forwardRef(TableCustom);
